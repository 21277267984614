import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles/tools';
import { Container } from './ui';

const StyledCardListingSection = styled.section`
  ${sectionMargins()};
`;

const StyledList = styled.ol`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${minBreakpointQuery.small`
    gap: 30px;
  `};

  ${minBreakpointQuery.medium`
    gap: 40px;
  `};

  ${minBreakpointQuery.mlarge`
    gap: 60px;
  `};

  ${minBreakpointQuery.large`
    gap: 80px;
  `};

  ${minBreakpointQuery.xlarge`
    gap: 100px;
  `};
`;

const StyledItem = styled.li``;

const CardListing = ({ children }) => (
  <Container>
    <StyledCardListingSection>
      <StyledList>
        {children.map(child => (
          <StyledItem>{child}</StyledItem>
        ))}
      </StyledList>
    </StyledCardListingSection>
  </Container>
);

export default CardListing;
