import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import CardListing from '../components/CardListing';
import ModularBlocks from '../components/ModularBlocks';
import NewsCard from '../components/NewsCard';

const NewsPage = () => {
  const {
    datoCmsNewsArchive: {
      seoMetaTags,
      title,
      bannerText,
      bannerImage,
      modularBlocks,
    },
    allDatoCmsNews: { nodes: posts },
  } = useStaticQuery(graphql`
    query NewsPageQuery {
      datoCmsNewsArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
        bannerImage {
          ...SubpageBannerImageFragment
        }
        modularBlocks {
          ...ContentModularBlockFragment
          ...FeaturedLogosModularBlockFragment
          ...LatestPressReleasesModularBlockFragment
        }
      }
      allDatoCmsNews(sort: { fields: meta___firstPublishedAt, order: DESC }) {
        nodes {
          ...NewsQueryFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} image={bannerImage} />
        <CardListing>
          {posts.map(({ title, slug, bannerImage, meta }) => (
            <NewsCard
              title={title}
              slug={slug}
              image={bannerImage}
              meta={meta}
            />
          ))}
        </CardListing>
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default NewsPage;
